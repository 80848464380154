import React from 'react'
import { Link } from 'gatsby'
import Layout from '../layouts'
import CommonHero from '../components/commonhero/commonhero.js'

const ThankYou = () => (
	<Layout head={{ title: 'Thank You' }}>
		<CommonHero pageTitle="Thank You" />
		<main className="bg-white">
			<div className="thank-you-section component">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-xl-11 mx-auto">
							<div className="text-center">
								<h5>
									We have received your message and will get back to you soon.
								</h5>
								<div className="d-flex justify-content-center mt-4">
									<Link className="alink hvr-shadow" to="/">
										Return Home
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</Layout>
)

export default ThankYou
